import {
  AudiencesIcon,
  MultichoiceIcon,
  SliderIcon,
  SpeedoIcon,
} from '@opinary/ui-components'

import { DASHBOARD_URL } from '../../api/constants'
import { PERMISSIONS } from '../../constants.ts'
import { Item, Subsection } from './CreateMenu/types.ts'
import { TRANSLATIONS } from './translations'

export const CREATE_SPEEDOMETER_DASHBOARD = `${DASHBOARD_URL}/new-poll/one-axis`
export const CREATE_MULTI_CHOICE_DASHBOARD = `${DASHBOARD_URL}/new-poll/ranking`
export const CREATE_SLIDER_DASHBOARD = `${DASHBOARD_URL}/new-poll/slider`
const CREATE_SPEEDOMETER_TOOL = '/library/poll/create/one-axis'
const CREATE_MULTI_CHOICE_TOOL = '/library/poll/create/ranking'
const CREATE_SLIDER_TOOL = '/library/poll/create/slider'

const CREATE_CAMPAIGN = '/audience/campaigns/create'

const CONTEXT_MENU_POLL_ITEMS: Item[] = [
  {
    text: TRANSLATIONS.SPEEDOMETER,
    Icon: SpeedoIcon,
    to: CREATE_SPEEDOMETER_DASHBOARD,
  },
  {
    text: TRANSLATIONS.MULTI_CHOICE,
    Icon: MultichoiceIcon,
    to: CREATE_MULTI_CHOICE_DASHBOARD,
  },
  {
    text: TRANSLATIONS.SLIDER,
    Icon: SliderIcon,
    to: CREATE_SLIDER_DASHBOARD,
  },
]

const TOOL_POLL_CREATOR_ITEMS: Item[] = [
  {
    text: TRANSLATIONS.SPEEDOMETER,
    Icon: SpeedoIcon,
    to: CREATE_SPEEDOMETER_TOOL,
  },
  {
    text: TRANSLATIONS.MULTI_CHOICE,
    Icon: MultichoiceIcon,
    to: CREATE_MULTI_CHOICE_TOOL,
  },
  {
    text: TRANSLATIONS.SLIDER,
    Icon: SliderIcon,
    to: CREATE_SLIDER_TOOL,
  },
]

const CONTEXT_MENU_CAMPAIGNS_ITEMS = [
  {
    text: TRANSLATIONS.AUDIENCES,
    Icon: AudiencesIcon,
    to: CREATE_CAMPAIGN,
  },
]

export const CONTEXT_MENU_ITEMS: Subsection[] = [
  {
    title: TRANSLATIONS.POLL_SUBHEADER,
    items: CONTEXT_MENU_POLL_ITEMS,
    permissions: [],
  },
  {
    title: TRANSLATIONS.CAMPAIGNS_SUBHEADER,
    items: CONTEXT_MENU_CAMPAIGNS_ITEMS,
    permissions: [PERMISSIONS.AUDIENCE],
  },
]

const TOOL_POLL_CREATOR_CONTEXT_MENU_ITEMS: Subsection[] = [
  {
    title: TRANSLATIONS.POLL_SUBHEADER,
    items: TOOL_POLL_CREATOR_ITEMS,
    permissions: [],
  },
  {
    title: TRANSLATIONS.CAMPAIGNS_SUBHEADER,
    items: CONTEXT_MENU_CAMPAIGNS_ITEMS,
    permissions: [PERMISSIONS.AUDIENCE],
  },
]

export const getCreateMenuItems = (
  toolPollCreatorEnabled: boolean
): Subsection[] => {
  if (toolPollCreatorEnabled) return TOOL_POLL_CREATOR_CONTEXT_MENU_ITEMS

  return CONTEXT_MENU_ITEMS
}
