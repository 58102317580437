import {
  H3,
  Notification,
  PasswordField,
  TextField,
} from '@opinary/ui-components'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { OTPModal } from '../../components'
import { Form, SubmitButton } from '../../components/AuthForm'
import { ERROR_CODES } from '../../constants.ts'
import { LIBRARY_PAGE } from '../Verify/constants.ts'
import useLogin from './hooks/useLogin'
import { OpinaryLogo, PageContainer } from './index.style'
import { TRANSLATIONS } from './translations'

type Props = {
  setIsPostLoginModalOpen: (isOpen: boolean) => void
  onLogin: () => void
}

export const Login = ({ setIsPostLoginModalOpen, onLogin }: Props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showOTPModal, setShowOTPModal] = useState(false)

  const navigate = useNavigate()
  const { login, isLoading, error, reset } = useLogin({
    onSuccess: ({ account }) => {
      if (!account?.toolEnabled) {
        window.location.href = LIBRARY_PAGE
        return
      }

      if (account?.mfaEnabled) {
        navigate('/verify')
        return
      }

      const shouldShowPostLoginModal =
        !account.scopes.includes('admin') &&
        account.email.match(/@opinary.com$/)

      if (shouldShowPostLoginModal) {
        setIsPostLoginModalOpen(true)
      }

      onLogin()
      navigate('/library')
    },
    onError: errorMsg => {
      setShowOTPModal(errorMsg?.code === ERROR_CODES.USER_BANNED)
    },
  })

  const isDisabled = isLoading || !email || !password

  const onSubmit = () =>
    login({
      email,
      password,
    })

  return (
    <PageContainer>
      <OpinaryLogo />
      <H3>{TRANSLATIONS.TITLE}</H3>
      <Form noValidate>
        <TextField
          id="email"
          data-testid="email"
          value={email}
          required
          requiredMsg={TRANSLATIONS.ERROR.REQUIRED}
          placeholder={TRANSLATIONS.LOGIN_PLACEHOLDER}
          label={TRANSLATIONS.LOGIN_NAME}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value)
          }}
        />
        <PasswordField
          id="password"
          data-testid="password"
          type="password"
          value={password}
          required
          requiredMsg={TRANSLATIONS.ERROR.REQUIRED}
          placeholder={TRANSLATIONS.PASSWORD_PLACEHOLDER}
          label={TRANSLATIONS.PASSWORD}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            reset()
            setPassword(event.target.value)
          }}
        />

        <SubmitButton
          data-testid="submit"
          onClick={onSubmit}
          disabled={isDisabled}
        >
          {TRANSLATIONS.LOG_IN_BUTTON}
        </SubmitButton>

        {error && <Notification type="error" message={error} />}
      </Form>
      <OTPModal isOpen={showOTPModal} />
    </PageContainer>
  )
}

export default Login
