import { IconText } from '../../index.ts'
import { MenuItemLink } from './CreateMenu.style.ts'
import { Item } from './types.ts'

type Props = Item & {
  onClick: () => void
}

const MenuItem = ({ text, Icon, to, onClick }: Props) => (
  <MenuItemLink key={text} to={to} onClick={onClick}>
    <IconText Icon={Icon} text={text} />
  </MenuItemLink>
)

export default MenuItem
