import { FontStyles, hasAccess, theme } from '@opinary/ui-components'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { getAccountDetails, getPermissions } from './api/auth.ts'
import setupAuthMiddleware from './api/axiosMiddleware.ts'
import { DASHBOARD_URL } from './api/constants.ts'
import useCustomers from './api/hooks/useCustomers.ts'
import { Customer } from './api/rest/types.ts'
import CustomerPicker from './components/CustomerPicker'
import PostLoginModal from './components/PostLoginModal'
import RequireAuth from './components/RequireAuth'
import { PERMISSIONS } from './constants.ts'
import AppProvider from './context/AppContext.tsx'
import GlobalStyle from './global.style'
import {
  Layout,
  Login,
  RagnarokPages,
  SkadiPages,
  ValhallaPages,
  VanaheimPages,
  Verify,
} from './pages'
import Logout from './pages/Logout'

const REDIRECT_QUERY_PARAM_KEY = 'redirectedFrom'

setupAuthMiddleware()

const App = () => {
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([])
  const [isPostLoginModalOpen, setIsPostLoginModalOpen] = useState(false)

  const accountDetails = getAccountDetails()
  const permissions = getPermissions()
  const { data, refetch } = useCustomers()

  const isAdmin = hasAccess(permissions, [PERMISSIONS.ADMIN])
  const isUnauthenticatedPage = ['/login', '/verify', '/logout'].includes(
    window.location.pathname
  )
  const isRedirectFromDashboard =
    new URL(window.location.href).searchParams.get(REDIRECT_QUERY_PARAM_KEY) ===
    'dashboard'
  if (
    !isUnauthenticatedPage &&
    !isRedirectFromDashboard &&
    accountDetails &&
    !accountDetails.toolEnabled
  ) {
    const redirectUrl = new URL(DASHBOARD_URL)
    redirectUrl.searchParams.set(REDIRECT_QUERY_PARAM_KEY, 'tool')
    window.location.href = redirectUrl.toString()
  }

  const handleModalClose = () => {
    setIsPostLoginModalOpen(false)
  }

  const CustomerPickerComponent = (
    <CustomerPicker
      setCustomers={setSelectedCustomers}
      availableCustomers={data?.results || []}
      selectedCustomers={selectedCustomers}
    />
  )

  useEffect(() => {
    if (data?.results?.length && !isAdmin) {
      setSelectedCustomers(data.results)
    }
  }, [data?.results, isAdmin])

  return (
    <AppProvider
      selectedCustomers={selectedCustomers}
      setSelectedCustomers={setSelectedCustomers}
      customers={data?.results ?? []}
    >
      <ThemeProvider theme={theme}>
        <FontStyles />
        <GlobalStyle />
        <PostLoginModal
          isOpen={isPostLoginModalOpen}
          onClose={handleModalClose}
        />
        <main data-testid="main">
          <Routes>
            <Route
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route path="/" element={<Navigate to="/dashboard/" replace />} />
              <Route
                path="/library/*"
                element={
                  <ValhallaPages
                    customers={data?.results ?? []}
                    selectedCustomers={selectedCustomers}
                    customerPicker={CustomerPickerComponent}
                  />
                }
              />
              <Route
                path="/dashboard/*"
                element={
                  <SkadiPages
                    customers={data?.results ?? []}
                    selectedCustomers={selectedCustomers}
                    customerPicker={CustomerPickerComponent}
                  />
                }
              />
              <Route
                path="/customer/*"
                element={
                  <VanaheimPages
                    customers={data?.results ?? []}
                    selectedCustomers={selectedCustomers}
                    customerPicker={CustomerPickerComponent}
                  />
                }
              />
              <Route
                path="/audience/*"
                element={
                  <RagnarokPages
                    customers={data?.results ?? []}
                    selectedCustomers={selectedCustomers}
                    customerPicker={CustomerPickerComponent}
                  />
                }
              />
            </Route>
            <Route
              path="/login"
              element={
                <Login
                  setIsPostLoginModalOpen={setIsPostLoginModalOpen}
                  onLogin={refetch}
                />
              }
            />
            <Route path="/verify" element={<Verify onLogin={refetch} />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </main>
      </ThemeProvider>
    </AppProvider>
  )
}

export default App
