import { AccessControl } from '@opinary/ui-components'
import { FC, SVGProps } from 'react'

import { IconText } from '../index.ts'
import { StyledLink, StyledListItem } from './index.style.ts'

type Props = {
  permissions: string[]
  validPermissions: string[]
  index: number
  id: string
  url: string
  icon: FC<SVGProps<SVGSVGElement>>
  text: string
}

const NavigationItem = ({
  permissions,
  validPermissions,
  index,
  id,
  url,
  icon,
  text,
}: Props) => {
  return (
    <AccessControl
      permissions={permissions}
      validPermissions={validPermissions}
      key={index}
    >
      <StyledListItem id={id}>
        <StyledLink to={url} data-testid={id}>
          <IconText Icon={icon} text={text} />
        </StyledLink>
      </StyledListItem>
    </AccessControl>
  )
}

export default NavigationItem
