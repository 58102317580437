import { hasAccess } from '@opinary/ui-components'
import { Fragment, RefObject } from 'react'

import { getAccountDetails, getPermissions } from '../../../api/auth.ts'
import { getCreateMenuItems } from '../constants.ts'
import { Menu, SubsectionTitle, SubsectionWrapper } from './CreateMenu.style.ts'
import MenuItem from './MenuItem.tsx'

type Props = {
  onClose: () => void
  ignoreClickRefs: RefObject<HTMLElement>[]
}

const CreateMenu = ({ onClose, ignoreClickRefs }: Props) => {
  const validPermissions = getPermissions()
  const account = getAccountDetails()
  const menuItems = getCreateMenuItems(account?.toolPollCreatorEnabled ?? false)

  return (
    <Menu onClose={onClose} ignoreClickRefs={ignoreClickRefs}>
      {menuItems.map(({ title, items, permissions }) => {
        if (!hasAccess(validPermissions, permissions)) return null

        return (
          <Fragment key={title}>
            <SubsectionWrapper>
              <SubsectionTitle>{title}</SubsectionTitle>
              {items.map(({ text, Icon, to }) => (
                <MenuItem
                  text={text}
                  Icon={Icon}
                  key={text}
                  to={to}
                  onClick={onClose}
                />
              ))}
            </SubsectionWrapper>
          </Fragment>
        )
      })}
    </Menu>
  )
}

export default CreateMenu
