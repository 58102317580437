import { DASHBOARD_URL } from '../../api/constants.ts'
import { Account } from '../../types.ts'

export const REDIRECT_QUERY_PARAM_KEY = 'redirectedFrom'

export const MAX_CODE_LENGTH = 6
export const POST_VERIFY_DELAY = 1000
export const LIBRARY_PAGE = `${DASHBOARD_URL}/library?${REDIRECT_QUERY_PARAM_KEY}=tool`

export const isToolUser = (user: Account | null): boolean => {
  if (!user) return false

  return user.toolEnabled ?? false
}
