import { Analytics, Block, Library, Revenue, Zap } from '@opinary/ui-components'

import { DASHBOARD_URL } from '../../api/constants'
import { PERMISSIONS } from '../../constants'
import { TRANSLATIONS } from './translations'

export const getEngageNavItems = (isToolUser: boolean) => {
  const toolLinks = [
    {
      url: '/library',
      id: 'libraryLink',
      text: TRANSLATIONS.LIBRARY_LINK,
      icon: Library,
      validPermissions: [],
    },
    {
      url: `/dashboard/analytics`,
      id: 'analyticsLink',
      text: TRANSLATIONS.ANALYTICS_LINK,
      icon: Analytics,
      validPermissions: [PERMISSIONS.ANALYTICS, PERMISSIONS.ADMIN],
    },
    {
      url: `/dashboard/revenue`,
      id: 'revenueLink',
      text: TRANSLATIONS.REVENUE_LINK,
      icon: Revenue,
      validPermissions: [PERMISSIONS.REVENUE, PERMISSIONS.ADMIN],
    },
    {
      url: `/customer/blocklist`,
      id: 'blocklistLink',
      text: TRANSLATIONS.BLOCKLIST_LINK,
      icon: Block,
      validPermissions: [PERMISSIONS.AUTOMATION, PERMISSIONS.ADMIN],
    },
    {
      url: `/customer/automation-matched`,
      id: 'automationMatchedLink',
      text: TRANSLATIONS.AUTOMATION_MATCHED_LINK,
      icon: Zap,
      validPermissions: [PERMISSIONS.AUTOMATION, PERMISSIONS.ADMIN],
    },
  ]

  const dashboardLinks = [
    {
      url: `${DASHBOARD_URL}/library/all`,
      id: 'libraryLink',
      text: TRANSLATIONS.LIBRARY_LINK,
      icon: Library,
      validPermissions: [],
    },
    {
      url: `${DASHBOARD_URL}/analytics`,
      id: 'analyticsLink',
      text: TRANSLATIONS.ANALYTICS_LINK,
      icon: Analytics,
      validPermissions: [PERMISSIONS.ANALYTICS, PERMISSIONS.ADMIN],
    },
    {
      url: `${DASHBOARD_URL}/report/revenue`,
      id: 'revenueLink',
      text: TRANSLATIONS.REVENUE_LINK,
      icon: Revenue,
      validPermissions: [PERMISSIONS.REVENUE, PERMISSIONS.ADMIN],
    },
    {
      url: `${DASHBOARD_URL}/automation/blacklist`,
      id: 'blocklistLink',
      text: TRANSLATIONS.BLOCKLIST_LINK,
      icon: Block,
      validPermissions: [PERMISSIONS.AUTOMATION, PERMISSIONS.ADMIN],
    },
    {
      url: `${DASHBOARD_URL}/automation/articles`,
      id: 'automationMatchedLink',
      text: TRANSLATIONS.AUTOMATION_MATCHED_LINK,
      icon: Zap,
      validPermissions: [PERMISSIONS.AUTOMATION, PERMISSIONS.ADMIN],
    },
  ]

  return isToolUser ? toolLinks : dashboardLinks
}

export const understandNavItems = [
  {
    url: '/audience/campaigns',
    id: 'audienceCampaignsLink',
    text: TRANSLATIONS.AUDIENCE_CAMPAIGNS_LINK,
    icon: Library,
    validPermissions: [],
  },
  {
    url: '/dashboard/audience/analytics',
    id: 'audienceAnalyticsLink',
    text: TRANSLATIONS.AUDIENCE_ANALYTICS_LINK,
    icon: Analytics,
    validPermissions: [],
  },
]

export const conversionNavItems = [
  {
    url: `/dashboard/conversion`,
    id: 'conversionLink',
    text: TRANSLATIONS.CONVERSION_LINK,
    icon: Analytics,
    validPermissions: [PERMISSIONS.CONVERSIONS, PERMISSIONS.ADMIN],
  },
]
