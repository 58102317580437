import React, { lazy, ReactNode, Suspense } from 'react'

import { Customer } from '../../api/rest/types.ts'

type ValhalaAppProps = {
  customerPicker: ReactNode
  selectedCustomers: Customer[]
  customers: Customer[]
}

const ValhallaApp = lazy<React.ComponentType<ValhalaAppProps>>(() =>
  import('valhalla/App').catch(() => {
    return { default: () => <div>Something went wrong</div> }
  })
)
const ValhallaPages = ({
  customers,
  customerPicker,
  selectedCustomers,
}: ValhalaAppProps) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ValhallaApp
        customers={customers}
        selectedCustomers={selectedCustomers}
        customerPicker={customerPicker}
      />
    </Suspense>
  )
}

export default ValhallaPages
