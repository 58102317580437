import React, { lazy, ReactNode, Suspense } from 'react'

import { Customer } from '../../api/rest/types.ts'

type SkadiAppProps = {
  customerPicker: ReactNode
  selectedCustomers: Customer[]
  customers: Customer[]
}

const SkadiApp = lazy<React.ComponentType<SkadiAppProps>>(() =>
  import('skadi/App').catch(() => {
    return { default: () => <div>Something went wrong</div> }
  })
)
const ConversionPage = ({
  customers,
  customerPicker,
  selectedCustomers,
}: SkadiAppProps) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SkadiApp
        customers={customers}
        selectedCustomers={selectedCustomers}
        customerPicker={customerPicker}
      />
    </Suspense>
  )
}

export default ConversionPage
