import React, { lazy, ReactNode, Suspense } from 'react'

import { Customer } from '../../api/rest/types.ts'

type VanaheimAppProps = {
  customerPicker: ReactNode
  selectedCustomers: Customer[]
  customers: Customer[]
}

const VanaheimApp = lazy<React.ComponentType<VanaheimAppProps>>(() =>
  import('vanaheim/App').catch(() => {
    return { default: () => <div>Something went wrong</div> }
  })
)
const VanaheimPages = ({
  customerPicker,
  selectedCustomers,
  customers,
}: VanaheimAppProps) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <VanaheimApp
        customers={customers}
        selectedCustomers={selectedCustomers}
        customerPicker={customerPicker}
      />
    </Suspense>
  )
}

export default VanaheimPages
