export const TRANSLATIONS = {
  ENGAGE_TITLE: 'Engage',
  LIBRARY_LINK: 'Library',
  ANALYTICS_LINK: 'Analytics',
  FEEDBACK_LINK: 'Feedback',
  HELP_LINK: 'Get Help',
  REVENUE_LINK: 'Revenue',
  CONVERSION_LINK: 'Conversion Analytics',
  BLOCKLIST_LINK: 'Blocklist',
  AUTOMATION_MATCHED_LINK: 'Automation Matched',
  AUDIENCE_TITLE: 'Audiences',
  CONVERSION_TITLE: 'Conversion',
  AUDIENCE_CAMPAIGNS_LINK: 'Audience Campaigns',
  AUDIENCE_ANALYTICS_LINK: 'Audience Analytics',
}
